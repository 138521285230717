import { RedocStandalone } from 'redoc';
import yaml from 'js-yaml';
import helpdeskapi from './helpdeskapi.yml';

const HelpdeskApiRedoc = () => {
    const helpdeskapiYaml = yaml.load(helpdeskapi);

    return (
        <div>{helpdeskapiYaml && (
            <RedocStandalone
                spec={JSON.parse(JSON.stringify(helpdeskapiYaml))}
                onLoaded={error => {
                    if (error) {
                        console.error(error);
                    } else {
                        console.log('loaded')
                    }
                }}
            />)
        }
        </div>
    )
}

export default HelpdeskApiRedoc;