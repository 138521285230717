import MspApiRedoc from "./MspApiRedoc";
import HelpdeskApiRedoc from "./HelpdeskApiRedoc";
import {BrowserRouter, Route} from 'react-router-dom';
import './App.css';
import FievaPdf from "./FievaPdf";
import {useEffect} from 'react';

function App() {
    useEffect(() => {
        document.title = "Developer portal"
    }, []);

    return (
        <BrowserRouter>
            <Route path="/" exact component={MspApiRedoc}/>
            <Route path="/pdf" component={FievaPdf}/>
            <Route path="/helpdeskapi" component={HelpdeskApiRedoc}/>
        </BrowserRouter>

    );
}

export default App;
