import {downloadPdf, getPdfList} from "../services/PdfService";
import {useEffect, useState} from 'react';

const extractVersionNumber = (fileName) => {
    const versionMatch = fileName.match(/(\d+(\.\d+)+)/);
    return versionMatch ? versionMatch[0] : '';
};

const FievaPdf = () => {
    const [listPdf, setListPdf] = useState([]);

    useEffect(() => {
        getPdfList().then(res => {
            const sortedFiles = res.sort((a, b) => {
                const versionA = extractVersionNumber(a.key);
                const versionB = extractVersionNumber(b.key);
                return versionA.localeCompare(versionB, undefined, { numeric: true });
            });
            setListPdf(sortedFiles.reverse());
        })
    }, []);

    const handleDownload = async (key) => {
        await downloadPdf(key);
    }

    return (listPdf.length > 0 && <div>
        <h2 className="heading">Latest version </h2>
        <ul className="file-list">
            <li key={listPdf[0].key} className="file-item">
                <div className="file-details">
                    <span className="file-name">{listPdf[0].key}</span>
                </div>
                <button className="download-button" onClick={() => handleDownload(listPdf[0].key)}>
                    Download
                </button>
            </li>
        </ul>
        <h2 className="heading">Older versions</h2>
        <ul className="file-list">
            {listPdf.slice(1).map(({ key, size, modified }, index) => (
                <li key={key} className="file-item">
                    <div className="file-details">
                        <span className="file-name">{key}</span>
                    </div>
                    <button className="download-button" onClick={() => handleDownload(key)}>
                        Download
                    </button>
                </li>
            ))}
        </ul>
    </div>)
}

export default FievaPdf;