import axios from "axios";
import { parseISO, format } from 'date-fns';
const baseUrl = process.env.REACT_APP_API_ENDPOINT;

const getPdfList = () => {
    return axios.get(`${baseUrl}/list`).then(response => {
        return response.data.list.map(item => ({
            ...item,
            modified: parseISO(item.modified)
        }));
    })
};

const downloadPdf = (name) => {
    return axios.get(`${baseUrl}/download/${name}`).then(response => {
        const buffer = response.data.file.Body.data;
        const uint8Array = new Uint8Array(buffer);
        const fileBlob = new Blob([uint8Array]);
        const url = URL.createObjectURL(fileBlob);
        const a = document.createElement("a");

        a.href = url;
        a.download = `${name}`;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        return 'Downloaded';
    })
};

export { getPdfList, downloadPdf }