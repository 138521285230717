import { RedocStandalone } from 'redoc';
import yaml from 'js-yaml';
import mspapiYaml from './mspapi.yml';

const MspApiRedoc = () => {
    const mspApiJson = yaml.load(mspapiYaml);

    return (
        <div>{mspApiJson && (
            <RedocStandalone
                spec={JSON.parse(JSON.stringify(mspApiJson))}
                onLoaded={error => {
                    if (error) {
                        console.error(error);
                    } else {
                        console.log('loaded')
                    }
                }}
            />)
        }
        </div>
    )
}

export default MspApiRedoc;